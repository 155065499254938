

































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import OrderOf from "@/views/productSku/salesManagement/orderOf.vue";
import api from "@/api";
import {
  OrderMainDto,
  OrderMainStoragePostDto,
  SelectListItem,
  WarehouseDto,
} from "@/api/appService";
import moment from "moment";
import ConfirmPayment from "@/views/productSku/orderManagement/confirmPayment.vue";
import OutStorage from "@/views/productSku/orderManagement/components/outStorage.vue";

@Component({
  name: "orderManagementList",
  components: {
    OutStorage,
    ConfirmPayment,
    PagedTableView,
    OrderOf,
  },
})
export default class orderManagementList extends Vue {
  queryForm = {
    orderCode: undefined,
    userName: undefined,
    receivingMethod: undefined,
    status: undefined,
    beginTime: undefined,
    endTime: undefined,
  };
  confirmPaymentId = "";
  orderReceivingMethodList = [] as SelectListItem[];
  orderStatusList = [] as SelectListItem[];
  customerTypeList = [] as SelectListItem[];
  orderPayMethodList = [] as SelectListItem[];

  showCloseTransactionDialog = false;
  closeTransactionForm = {
    id: undefined,
    cancelReason: undefined,
  } as any;
  showOrderDeliveryDialog = false;
  orderDeliveryForm = {
    id: undefined,
    expectedDeliveryDate: undefined,
  } as OrderMainStoragePostDto;

  selectionList: OrderMainDto[] = [];

  roleRule = {
    expectedDeliveryDate: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "orderManagement.selectExpectedDeliveryDate"
        ),
        trigger: "blur",
      },
    ],
  };

  created() {
    this.fetchOrderReceivingMethodList();
    this.fetchOrderStatusList();
    this.fetchCustomerTypeList();
    this.fetchOrderPayMethodList();
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.orderMain.getAll(params);
  }

  async fetchOrderReceivingMethodList() {
    await api.enumService
      .getValues({ typeName: "OrderReceivingMethod" })
      .then((res) => {
        this.orderReceivingMethodList = res;
      });
  }

  async fetchOrderStatusList() {
    await api.enumService.getValues({ typeName: "OrderStatus" }).then((res) => {
      this.orderStatusList = res;
    });
  }

  async fetchCustomerTypeList() {
    await api.enumService
      .getValues({ typeName: "CustomerType" })
      .then((res) => {
        this.customerTypeList = res;
      });
  }

  async fetchOrderPayMethodList() {
    await api.enumService
      .getValues({ typeName: "OrderPayMethod" })
      .then((res) => {
        this.orderPayMethodList = res;
      });
  }

  getOrderReceivingMethodText(value: any) {
    let text = "";
    for (let i in this.orderReceivingMethodList) {
      if (this.orderReceivingMethodList[i].value === value) {
        text = this.orderReceivingMethodList[i].text!;
      }
    }
    return text;
  }

  getOrderPayMethodText(value: any) {
    let text = "";
    for (let i in this.orderPayMethodList) {
      if (this.orderPayMethodList[i].value === value) {
        text = this.orderPayMethodList[i].text!;
      }
    }
    return text;
  }

  getStatusText(value: any) {
    let text = "";
    for (let i in this.orderStatusList) {
      if (this.orderStatusList[i].value === value) {
        text = this.orderStatusList[i].text!;
      }
    }
    return text;
  }

  handleOrderOf() {
    // (this.$refs.orderOf as any).show = true;

    window.open((this as any).portalLoginUrl, "_blank");
  }

  jumpDetail(index: number, row: OrderMainDto) {
    this.$router.push({
      name: "orderDetail",
      params: { id: row.id!.toString() },
    });
  }

  handleCloseTransaction(index: number, row: OrderMainDto) {
    this.showCloseTransactionDialog = true;
    this.closeTransactionForm.id = row.id;
  }

  async closeTransaction() {
    await api.orderMain
      .cancelled({ body: this.closeTransactionForm })
      .then(() => {
        this.$message.success(
          (this as any).$l.getLanguageText(
            "orderManagement.orderClosed"
          ) as string
        );
        this.showCloseTransactionDialog = false;
        setTimeout(() => {
          (this as any).$bus.$emit("data-updated");
        }, 2000);
      })
      .catch((err) => {
        this.$message.error(err);
      });
  }

  handleOrderDelivery(index: number, row: OrderMainDto) {
    this.orderDeliveryForm = {
      id: row.id,
    };
    this.showOrderDeliveryDialog = true;
  }

  endTimeHandle() {
    if (moment(this.queryForm.endTime) < moment(this.queryForm.beginTime)) {
      this.$message.warning(
        (this as any).$l.getLanguageText("EndTimeBigger") as string
      );
    }
  }

  handleCompleteDelivery(index: number, row: OrderMainDto) {
    this.$confirm(
      (this as any).$l.getLanguageText(
        "orderManagement.deliveryCompleted"
      ) as string,
      (this as any).$l.getLanguageText("basic.tip") as string
    ).then(async () => {
      await api.orderMain
        .received({
          body: {
            id: row.id,
          },
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: (this as any).$l.getLanguageText(
              "orderManagement.successfulOperation"
            ) as string,
          });
          setTimeout(() => {
            (this as any).$bus.$emit("data-updated");
          }, 2000);
        });
    });
  }

  handleConfirmPayment(index: number, row: OrderMainDto) {
    this.confirmPaymentId = row.id!;
    (this.$refs.confirmPayment as any).show = true;
    (this.$refs.confirmPayment as any).form = row;
  }

  handleSelectable(row: OrderMainDto) {
    return !!row.generatedInvoicePath;
  }

  // 获取已选择数据
  getSelectionList(rows: OrderMainDto[]) {
    this.selectionList = rows;
  }

  handleBatchDownloadInvoice() {
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: (this as any).$l.getLanguageText(
          "salesManagement.atLeastOnePieceOfData"
        ) as string,
      });
      return;
    }

    let orderMainIds = this.selectionList
      .map((item: OrderMainDto) => item.id)
      .join(",");
    window.open(
      `${this.baseURL}/api/services/app/OrderMain/BatchDownloadInvoicePdf?orderMainIds=${orderMainIds}`
    );
  }
}


















































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import { OrderMainDto } from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "ConfirmPayment",
})
export default class ConfirmPayment extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: string;

  loading = true;
  show = false;
  form: OrderMainDto = {};
  defaultData: OrderMainDto = {
    id: "",
  };

  linkRule = {
    thirdPartyTransactionCode: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "orderManagement.tips.checkNumber"
        ),
        trigger: "blur",
      },
    ],
  };

  get title() {
    return (this as any).$l
      .getLanguageText("orderManagement.confirmReceivable")
      .toString();
  }

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      if (this.dataId) {
        api.orderMain
          .confirmPayment({
            body: this.form,
          })
          .then(() => {
            this.show = false;
            this.$message.success(
              (this as any).$l.getLanguageText("basic.updateSuccess").toString()
            );
          });
      }
    });
  }

  @Watch("show")
  onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        this.loading = true;
        api.orderMain
          .get({ id: this.dataId })
          .then((res: OrderMainDto) => {
            this.form = res!;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  cancel() {
    this.show = false;
  }
}
